import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import { BigLogo } from './styles'
import tshirtWeek from 'src/static/images/shirtweek.svg'

const Logo = () => {
  return (
    <React.Fragment>
      <Link to="/">
        <BigLogo src={tshirtWeek} alt="Lobster & Lemonade Logo" />
      </Link>
    </React.Fragment>
  )
}

export default Logo
