import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import 'src/static/fonts.css'
import { Global, css } from '@emotion/react'
import { IS_BLACK_FRIDAY } from './globals'

export const breakpoints = {
  xs: 320,
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1440,
  tablet: 1024,
}

export const fonts = {
  regular: 'LL-NeuePlakText-Regular',
  bold: 'LL-NeuePlakText-Bold',
  extendedSemiBold: 'LL-NeuePlak-ExtendedSemiBold',
  extendedBold: 'LL-NeuePlak-ExtendedBold',
  caxtonBoldItalic: 'Caxton-Bold-Italic',
  nightwraith: 'Nightwraith Two-Tone Italic',
  prohibitionRegular: 'Prohibition Regular',
  vinaSans: 'VinaSans Regular',
}

export const fontSizes = {
  headlineMain: '4.571em',
  headlineMainMobile: '2.714em',
  headlineMainTablet: '2.714em',
  headlineMedium: '4em',
  headline: '2.57em',
  headlineTablet: '2em',
  headlineTabletPortrait: '2.3em',
  headlineMobile: '1.143em',
  subHeadline: '1.28em',
  subHeadlineMobile: '0.714em',
  subHeadlineDefault: '1em',
  text: '1.29em',
  sectionHeadlineMobile: '2.2em',
  textMobile: '1.07em',
  footerText: '1.21em',
  footerTextMobile: '1.143em',
  footerTextSmall: '0.857em',
  footerTextSuperSmall: '0.7em',
  footerTextSmallMobile: '1.075em',
  mediumText: '1.3em',
  smallText: '0.9em',
  productDetailTitleMobile: '1.43em',
  productDetailsDesktop: '1.28em',
  verticalNavigationText: '16px',
  addToBagButton: '1.42em',
  addToBagButtonMobile: '1.22em',
  sizingTable: '14px',
  productDetailsMobile: '0.85em',
  bagQuantity: '0.78em',
  searchBarDesktop: '16px',
  searchBarMobile: '15px',
  filterTextDesktop: '18px',
  filterTextMobile: '14px',
  allProductsDesktop: '2.57em',
  allProductsiPad: '1.5em',
  allProductsMobile: '1em',
  totalProducts: '18px',
  sidebarMobileHeadline: '1.5em',
  sidebarMobileLinks: '18px',
  resultsText: '1.21em',
  resultsTextMobile: '0.78em',
  resultsButton: '1.42em',
  resultsButtonMobile: '1.21em',
  productTagDefault: '0.9em',
  productTagMobile: '0.5em',
  filterContainer: '18px',
  filterContainerMobile: '14px',
  productTileInfoMobile: '0.85em',
  productTileInfoTablet: '1em',
  productTileInfo: '1.21em',
  expandedLink: '14px',
  sortingContainerDesktop: '18px',
  sortingContainerMobile: '14px',
  stickyBannerBigText: '1.8em',
  stickyBannerSmallText: '1.3em',
  specialLayoutTitle: '1.25em',
}

export const miscStyles = {
  letterSpacing: '2pt',
  letterSpacingMobile: '1.25pt',
}

//const lemonadeYellow = '#FEE131'
const actionYellow = '#F9E300'

export const colors = {
  darkGray: IS_BLACK_FRIDAY ? 'white' : '#333131',
  darkGrayNoInverter: '#333131',
  midGray: IS_BLACK_FRIDAY ? 'white' : '#333131CC',
  gray: IS_BLACK_FRIDAY ? 'white' : '#33313180',
  lemonadeYellow: actionYellow,
  lobsterRed: '#F08262',
  lightGray: IS_BLACK_FRIDAY ? 'black' : '#F8F7F2',
  lightGrayFooter: IS_BLACK_FRIDAY ? '#1b1b1b' : '#F8F7F2',
  soldOut: '#b8b7b7',
  placeholderGray: IS_BLACK_FRIDAY ? 'white' : '#33313159',
  whiteWithInverter: IS_BLACK_FRIDAY ? 'black' : 'white',
  nflBlue: '#2A265C',
  nflHighlightYellow: '#F8E800',
  squeezeWeekBlue: '#1D79A0',
}

export const filterColors = {
  White: '#FFFFFF',
  white: '#FFFFFF',
  Cream: '#EFE4CE',
  cream: '#EFE4CE',
  Beige: '#e8dcca',
  beige: '#e8dcca',
  Black: '#000000',
  black: '#000000',
  Gray: '#808080',
  gray: '#808080',
  Yellow: '#FEDF00',
  yellow: '#FEDF00',
  Brown: '#88540B',
  brown: '#88540B',
  Orange: '#FF7F00',
  orange: '#FF7F00',
  Coral: '#FF7F50',
  coral: '#FF7F50',
  Pink: '#FFC0CB',
  pink: '#FFC0CB',
  Purple: '#800080',
  purple: '#800080',
  Red: '#ED1C24',
  red: '#ED1C24',
  Maroon: '#800000',
  maroon: '#800000',
  Green: '#008000',
  green: '#008000',
  Navy: '#202A44',
  navy: '#202A44',
  Turquoise: '#31c3a6',
  turquoise: '#31c3a6',
  Gold: '#A57C00',
  gold: '#A57C00',
  Blue: '#0018A8',
  blue: '#0018A8',
  Multicolor: '',
  multicolor: '',
}

export const GlobalStyle = () => (
  <Global
    styles={css`
      body {
        margin: 0;
        color: ${colors.darkGray};
        font-family: ${fonts.regular}, sans-serif;
        font-size: 14px;
        background-color: ${IS_BLACK_FRIDAY ? 'black' : 'inherit'};
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover;
      }
      html {
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
    `}
  />
)

export const StAllProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 4fr;
  padding-bottom: 4rem;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr;
  }
`

export const Img = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
  padding: 0 0 1.45rem 0;
  margin: 0 0 1.45rem 0;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0;
  }
`

export const ProductDetailsMobileImage = styled(Img)`
  margin-bottom: 1.5rem !important;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1920px;
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`
